
/*
h1 { font-family: Consolas, monaco, monospace; font-size: 24px; font-style: normal; font-variant: normal; font-weight: 700; line-height: 26.4px; } h3 { font-family: Consolas, monaco, monospace; font-size: 14px; font-style: normal; font-variant: normal; font-weight: 700; line-height: 15.4px; } p { font-family: Consolas, monaco, monospace; font-size: 14px; font-style: normal; font-variant: normal; font-weight: 400; line-height: 20px; } blockquote { font-family: Consolas, monaco, monospace; font-size: 21px; font-style: normal; font-variant: normal; font-weight: 400; line-height: 30px; } pre { font-family: Consolas, monaco, monospace; font-size: 13px; font-style: normal; font-variant: normal; font-weight: 400; line-height: 18.5714px; }
*/

/***** Header *****/

.header {
  width: 100%;
  height: 100vh;
  background-color: #090300;
  color: #F7F7F7;
  position: relative;
}

@media only screen and (max-width: 960px) {
  .header {
    height: 100vh;
  }
}

.header-name-tagline-wrapper {
  position: absolute;
  top: 30%;
  left: 10%;
  transform: translate(0%, -50%);
}

.header-name {
  margin-bottom: 5vh;
  font-size: 4vw;
}

@media only screen and (max-width: 960px) {
  .header-name {
    margin-bottom: 3vh;
    font-size: 8vw;
  }
}

.header-tagline {
  font-size: 2vw;
}

@media only screen and (max-width: 960px) {
  .header-tagline {
    font-size: 5vw;
  }
}

.header-tagline b {
  color: #DB2D20;
}

.header-scroll-prompt {
  position: absolute;
  bottom: 0%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: gray;
  font-size: 1vw;
  text-align: center;
}

@media only screen and (max-width: 960px) {
  .header-scroll-prompt {
    font-size: 5vw;
  }
}



/***** Nav *****/


.nav {
  width: 100%;
  height: 50px;
  background-color: #090300;
  color: #F7F7F7;
  z-index: 10;
}



.nav-links {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.nav-links a {
  margin-left: 5px;
  margin-right: 5px;
  padding: 15px;
  font-size: 20px;
  cursor: pointer;
  color: white;
  text-decoration: none;
}

.sticky + .content {
  padding-top: 50px;
}

.sticky {
  width:100%;
  position: fixed;
  top: 0;
}

.nav-name {
  display: block;
  position: absolute;
  padding: 15px;
  font-size: 20px;
  cursor: pointer;
}

.nav-name b {
  color: #DB2D20;
}

.nav-menu-button {
  display: none;
}

@media only screen and (max-width: 960px) {
  .nav-links {
    display: block;
    top: 50px;
    position: fixed;
    width: 100%;
    flex-direction: column;
    justify-content: space-evenly;
    height: 50vh;
    text-align: right;
    left: 100vh;
    transition: left 0.7s;
    -webkit-transition: left 0.7s; /* For Safari 3.1 to 6.0 */
  }

  .nav-links-show {
    left: 0;
  }

  .nav-links a {
    display: block;
    background-color: #090300;
    padding: 15px;
    margin: 0;
    cursor: pointer;
  }


  .nav-menu-button {
    display: block;
    top: 0;
    position: absolute;
    font-size: 30px;
    padding: 5px;
    padding-right: 10px;
    right: 0px;
    cursor: pointer;
    transition: top 0.7s;
    -webkit-transition: top 0.7s; /* For Safari 3.1 to 6.0 */
  }

  #nav-menu-button i {
    cursor: pointer;
  }
}



/***** Terminal Text *****/

.terminal-text b {
  color: #DB2D20;
}



/***** Content *****/

.content {
  max-width: 1000px;
  margin: auto;
  padding-bottom: 3vw;
  padding-left: 3vw;
  padding-right: 3vw;
}

@media only screen and (max-width: 960px) {
  .content {
    padding-bottom: 5vw;
    padding-left: 5vw;
    padding-right: 5vw;
  }

}

aside {
  font-style: italic;
  color:gray;
}


.about-me i {
  font-size: 120%;
}

.about-me a {
  text-decoration: none;
}

.pic {
  width: 60%;
  margin: 20px; 
  border-radius: 8px;

  box-shadow: 5px 10px 18px #888888;
}

.pic-phone {
  width: 30%;
  margin: 20px; 
  border-radius: 8px;

  box-shadow: 5px 10px 18px #888888;
}

/* "terminal.background":"#090300",
"terminal.foreground":"#A5A2A2",
"terminalCursor.background":"#A5A2A2",
"terminalCursor.foreground":"#A5A2A2",
"terminal.ansiBlack":"#090300",
"terminal.ansiBlue":"#01A0E4",
"terminal.ansiBrightBlack":"#5C5855",
"terminal.ansiBrightBlue":"#01A0E4",
"terminal.ansiBrightCyan":"#B5E4F4",
"terminal.ansiBrightGreen":"#01A252",
"terminal.ansiBrightMagenta":"#A16A94",
"terminal.ansiBrightRed":"#DB2D20",
"terminal.ansiBrightWhite":"#F7F7F7",
"terminal.ansiBrightYellow":"#FDED02",
"terminal.ansiCyan":"#B5E4F4",
"terminal.ansiGreen":"#01A252",
"terminal.ansiMagenta":"#A16A94",
"terminal.ansiRed":"#DB2D20",
"terminal.ansiWhite":"#A5A2A2",
"terminal.ansiYellow":"#FDED02" */
